import Vue from 'vue'
import App from './App.vue'

// Append VIEWER style only on VIEWER website
const nonPlayerDomains = [ 'bannerse.com', 'www.bannerse.com', 'test.bannerse.com', 'dev.bannerse.com' ];
const isViewer = document
  && document.location
  && (document.location.host.endsWith('bannerse.com') || document.location.host.includes('localhost'))
  && !nonPlayerDomains.includes(document.location.host);

if (
  isViewer
  && (
    typeof window.smucio == 'undefined'
    || (
      typeof window.smucio != 'undefined'
      && typeof window.smucio.styled == 'undefined'
    )
  )
) {
  window.smucio = window.smucio || { apikeys: [] };
  window.smucio.styled = true

  require('./assets/scss/styles.scss')
}

Vue.config.productionTip = false

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL

let SmucioStreamview;
if (process.env.NODE_ENV === 'production') {
  require('smucio-streamview/dist/smucio-streamview.hashed.css');
  SmucioStreamview = require('smucio-streamview/dist/smucio-streamview.common.hashed.js');
}
else {
  require('smucio-streamview/dist/smucio-streamview.css');
  SmucioStreamview = require('smucio-streamview/dist/smucio-streamview.common.js');
}
Vue.use(SmucioStreamview.default)

if (window && window.location && window.location.pathname === '/stream/' && window.location.search) {
  let parameterBag = new URLSearchParams(window.location.search)
  if (parameterBag.has('apikey')) {
    window.smucio = window.smucio || { apikeys: [] };
    window.smucio.apikeys.push({
      key: decodeURIComponent(parameterBag.get('apikey')),
      mode: 'viewer',
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.smucio.apikeys.forEach(item => {
    if (item.loaded) {
      return;
    }
    item.loaded = true;

    let elid = 'smuc-player';
    if (item.mode !== 'viewer') {
      elid += '--' + item.key;
    }

    let $el = null;
    if (item.mode === 'overlay') {
      $el = document.createElement('div');
      $el.id = elid;
      document.body.appendChild($el);
    }
    else {
      $el = document.getElementById(elid);
    }
    if (!$el) {
      return;
    }

    // H4ck: Multiple embeds with same apikey.
    if (item.mode !== 'viewer') {
      $el.id += '-' + Math.random().toString().substring(2, 6);
    }

    // Indamedia desktop.
    if (
      item.key === 'dc10ce63b8da98a13a20b8ac7e5a711442d0f362'
      || item.key === 'c657d0b017ac664a3db5f504d97594419f8a83af'
      || item.key === '3b4f43531538b6c3960abcd24454534331ca68a0'
    ) {
      const req = new XMLHttpRequest();
      req.open('GET', 'https://indexadhu.hit.gemius.pl/redot.gif?id=ciU66M8hk0.eMomEzObhSMQ6zZX_nQNk5SBynogoSQX.Q7/stparam=rfgpfsftfh/fastid=muaihujarqgtbvjxodbcgngsijki/nc=0');
      req.send();
    }
    // Indamedia mobile.
    if (
      item.key === 'b3891eb0331d8030944b3756a3ea445e93211ed1'
      || item.key === '20f174963024876e74a881c1ca62c8c871976515'
      || item.key === '264059624449216169c4b94932e2a9725dca66c8'
    ) {
      const req = new XMLHttpRequest();
      req.open('GET', 'https://indexadhu.hit.gemius.pl/redot.gif?id=ciU66M8hk0.eMomEzObhSMQ6zZX_nQNk5SBynogoSQX.Q7/stparam=kdrllkifqw/fastid=idcemtxfgznlnwnipblqvurmllmk/nc=0');
      req.send();
    }

    function buildApp() {
      class ClonedVue extends Vue { }

      let clickTrackerUrl = $el.getAttribute('data-click-tracker');
      if (clickTrackerUrl) {
        clickTrackerUrl = decodeURIComponent(decodeURIComponent(clickTrackerUrl));
      }

      new ClonedVue({
        data: {
          'smucapikey': item.key,
          'smucplayermode': item.mode,
          'optimizeForAd': typeof item.optimizeForAd == 'number' && item.optimizeForAd == 1,
          'clickTracker': clickTrackerUrl,
          chat: {
            currentUserId: null,
            messages: [],
            animatedMessages: [],
            pinnedMessage: null,
            hasMore: true,
            isLoading: false,
            inited: false,
            firstMessagesReceived: false,
            bannedUsers: [],
          },
        },
        render: h => h(App)
      }).$mount($el);
    }

    new IntersectionObserver((entries, observer) => {
      const isVisible = entries[0].isIntersecting;
      if (isVisible) {
        observer.disconnect();
        buildApp();
      }
    }, { root: null, rootMargin: '200px' }).observe($el);
  });
});
